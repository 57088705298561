import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import NavInn from "../components/inner/navInn"
import SEO from "../components/seo"
import Hero from "../components/common/hero"
import Btn from "../components/common/button"


const ContactPage = () => (
  <Layout>
    <SEO title="Subscribe to our Newsletter" />
    <Hero className="hero--inn hero--sub">
      <div className="cont--spaced">
        <div className="hero__logo__cont">
          <a href="/" className="hero__logo hero__logo--withtext">PrimeSet</a>
        </div>
        <div className="hero--sub__content">
          <h1 className="title--main">PrimeSet in NYC</h1>
          <h2 className="title--md">We are getting together with <br/> <span className="typ--strong">Jeff Weiser, CMO of Shopify</span>, <span className="typ--strong">Babak Hamadani, Founder of PrimeSet</span> <br/>& a few founder / engineer friends for drinks.</h2>
          <button id="eventbrite-widget-modal-trigger-76682578657" type="button" className="btn btn--white">Sign up for the event</button>

          <br/>
          <br/>
          <br/>

          <p className="hero--sub__text">Subscribe to our mailing list to get the latest updates & events from Primeset.</p>


          <form className="form__cont form--subs"  name="subscribeLoc" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="subscribeLoc" />

            <div className="input input--lg input--btn">
              <input type="text" placeholder="Email Address" name="Email" />
            </div>
            <div className="input input--lg">
              <input type="text" placeholder="Location ( Optional )" name="location" />
            </div>

            <button className="btn btn--lg btn--fw btn--prim" type="submit" data-hover="Subscribe">Subscribe</button>

            <span className="input__note">Subscribe & we will get in touch!</span>
          </form>
        </div>
      </div>
    </Hero>
  </Layout>
)

export default ContactPage
